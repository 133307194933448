<template>
  <div>
    <CRow>
      <CCol>
        <h4 class="text-uppercase pb-4">Water Vehicle Request Detail</h4>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="4">
        <CCard class="p-5" v-if="waterVehicleRequest.customer">
          <CRow>
            <CCol class="avatar-container">
              <img
                class="mb-2"
                name="cil-user"
                src="/user.PNG"
                alt="User Logo"
              />
            </CCol>

            <CCol class="mt-3">
              <h4>
                {{waterVehicleRequest.customer.salutation + " " + waterVehicleRequest.customer.first_name + " " +waterVehicleRequest.customer.last_name }}
              </h4>
              <p>Account No #{{waterVehicleRequest.customer.account_number }}</p>
            </CCol>
          </CRow>

          <ul class="customer-detail-list">
            <!-- <li>
              <p>Conn. Type:</p>
              <p>
                {{waterVehicleRequest.customer.connection_type }}
              </p>
            </li>
            <li>
              <p>Category:</p>
              <p>{{waterVehicleRequest.customer.category }}</p>
            </li> -->

            <li>
              <p>Mobile No:</p>
              <p>{{waterVehicleRequest.customer.mobile_number }}</p>
            </li>
            <li>
              <p>Email:</p>
              <p>{{waterVehicleRequest.customer.email }}</p>
            </li>
            <li>
              <p>City:</p>
              <p>{{waterVehicleRequest.customer.city }}</p>
            </li>
            <li>
              <p>State:</p>
              <p>{{waterVehicleRequest.customer.state }}</p>
            </li>

             <!-- <li>
              <p>Ward No:</p>
              <p>{{waterVehicleRequest.customer.ward_number }}</p>
            </li> -->
            <!-- <li>
              <p>Address:</p>
              <p>{{waterVehicleRequest.customer.address }}</p>
            </li> -->
          </ul>
        </CCard>
      </CCol>
      <CCol sm="8">
        <CRow>
          <CCol sm="12">
            <CCard>
              <h4 class="pb-4">Water Vehicle Request</h4>

              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <!-- <th class="font-weight-bold">Meter No</th> -->
                      <th class="font-weight-bold">Water Volume</th>
                      <th class="font-weight-bold">Address</th>
                      <th class="font-weight-bold">Mobile Number</th>
                      <th class="font-weight-bold">Amount (BDT)</th>
                      <th class="font-weight-bold">Payment Method</th>
                      <th class="font-weight-bold">Longitude</th>
                      <th class="font-weight-bold">Latitude</th>
                      <th class="font-weight-bold">Status</th>
                      <th class="font-weight-bold">Created At</th>
                    </tr>
                  </thead>
                  <tbody v-if="waterVehicleRequest">
                    <tr class="text-center">
                      <td>
                        {{
                          waterVehicleRequest.water_volume +
                          " " +
                          waterVehicleRequest.water_unit
                        }}
                      </td>
                      <td>{{ waterVehicleRequest.address }}</td>
                      <td>{{ waterVehicleRequest.mobile_number }}</td>
                      <td>{{ waterVehicleRequest.total_amount }}</td>
                      <td>{{ waterVehicleRequest.payment_method }}</td>
                      <td>{{ waterVehicleRequest.longitude }}</td>
                      <td>{{ waterVehicleRequest.latitude }}</td>
                      <td>
                        <span
                          class="badge status-badge"
                          @click="checkUsrPermissions(['WV_all', 'WV_mad']) && markAsDelivered(waterVehicleRequest)"
                          :title="
                            waterVehicleRequest.status === 'CONFIRMED'
                              ? 'Click here to mark as delivered.'
                              : ''
                          "
                          :class="
                            waterVehicleRequest.status === 'CONFIRMED'
                              ? 'badge-primary'
                              : 'badge-success'
                          "
                          >{{ reStatus[waterVehicleRequest.status] }}
                        </span>
                      </td>
                      <td>{{ dateFormat(waterVehicleRequest.created_at) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CCard>
              <h4 class="pb-4">Payments</h4>

              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th class="font-weight-bold">Payment Method</th>
                      <th class="font-weight-bold">Payment Type</th>
                      <th class="font-weight-bold">Payment Status</th>
                      <th class="font-weight-bold">Payment Amount</th>
                      <th class="font-weight-bold">Transaction Id</th>
                      <th class="font-weight-bold">Paid At</th>
                    </tr>
                  </thead>
                  <tbody v-if="waterVehicleRequest.payments.length">
                    <tr
                      v-for="payment in waterVehicleRequest.payments"
                      :key="payment.id"
                    >
                      <td>{{ payment.payment_method }}</td>
                      <td>{{ payment.payment_type }}</td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            payment.status === 'Successful'
                              ? 'badge-primary'
                              : 'badge-warning'
                          "
                          >{{ payment.status }}
                        </span>
                      </td>
                      <td>{{ payment.amount }}</td>
                      <td>{{ payment.transaction_id }}</td>
                      <td>{{ dateFormat(payment.created_at) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "WaterVehicleRequestView",

  data: () => {
    return {
      reStatus: { CONFIRMED: "Delivery Pending", COMPLETED: "Delivered" },

      // waterVehicleRequest: { payments: [] },
      waterVehicleRequestId: Number,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      // isLoading: false,
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Water Vehicle Requests", path:"/water-vehicle-requests", isActive: false}, {text:"View", path:"", isActive: true}]
    };
  },
  methods: {
    markAsDelivered(request) {
      try {
        this.$store.dispatch("WaterVehicleRequest/markAsDelivered", request);
      } catch (err) {
        console.log(err);
      }
    },

    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
  },
  watch: {
    $route(to, from) {
      this.waterVehicleRequestId = this.$route.params.id;
      this.getMeter();
    },
  },
  mounted() {
    this.waterVehicleRequestId = this.$route.params.id;
    // this.getWaterVehicleRequest();
    this.$store.dispatch(
      "WaterVehicleRequest/getWaterVehicleRequestById",
      this.waterVehicleRequestId
    );
  },
  computed: {
    ...mapGetters("WaterVehicleRequest", ["waterVehicleRequest"]),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.update-status-btn-width {
  width: 100%;
}

.hide-footer >>> footer {
  display: none;
}

.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px !important;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>


<style lang="scss" scoped>
.avatar-container {
  max-width: 100px;
}
.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}
div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>

